/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages
        jQuery(document).ready(function(){

          $('.slick-hero').slick({
            fade: true,
            autoplay: true,
            autoplaySpeed: 2000
          });

          // Offset anchor links to take into account fixed header
          function delayedFragmentTargetOffset(){
              var offset = $(':target').offset();
              if(offset){
                  var scrollto = offset.top - 120; // minus fixed header height
                  $('html, body').animate({scrollTop:scrollto}, 0);
              }
          }

          $(function() {
              setTimeout(delayedFragmentTargetOffset, 500);
          });

          // plugins here
           // full height elements
          if ($(window).width() > 767) {
            windowHeight = $(window).height(); $('.full-height').css('height', windowHeight -270);
            $(window).on('resize', function(){
            windowHeight = $(window).height(); $('.full-height').css('height', windowHeight -270);
            });
          }

          // Isotope Filter
          var $grid = $('#isotope').isotope({
            // options
          });
          // filter items on button click
          $('.filter-button').on( 'click', 'a', function(event) {
            event.preventDefault();
            $('.filter-button').removeClass('active-button');
            $(this).parent('.filter-button').addClass('active-button');
            var filterValue = $(this).attr('data-filter');
            $grid.isotope({ filter: filterValue });
          });
          // layout Isotope after each image loads
          $grid.imagesLoaded().progress( function() {
            $grid.isotope('layout');
          });

          // Fancybox
          $(".fancybox").fancybox({
            prevEffect  : 'none',
            nextEffect  : 'none',
            padding: 0,
            helpers : {
              title : {
                type: 'outside'
              },
              thumbs  : {
                width : 100,
                height  : 100
              }
            },
            beforeShow: function () {
                // set size to (fancybox) img
                  $(".fancybox-image").css({
                      "width": 1065,
                      "height": 625
                  });
                  $('.project-image').find('.fancy-style').removeClass('no-fancybox');
                  // set size for parent container
                  this.width = 1065;
                  this.height = 625;
              },
              afterClose: function () {
                $('.project-image').find('.fancy-style').addClass('no-fancybox');
              },
          });

          // Share Price Tabs
          $( "#tab-container" ).tabs();


          // Search Open
          $('.search-icon').on('click', function() {
            $('.searchform-block').stop().toggleClass('search-active');
            $('.searchform-block').find('input[type="text"]').val("");
            if ($(window).width() > 476) {
              $('.searchform-block').find('input[type="text"]').focus();
            }
          });

          // Mobile Menu
          var hamburger = $('#hamburger-icon');
            hamburger.click(function() {
               hamburger.toggleClass('active');
               return false;
          });

          $('.m-menu a').on('click', function() {
            $('#mastnav').stop().slideToggle();
          });
          $('li.menu-item-has-children a').on('click', function() {
            $(this).siblings('ul.sub-menu').stop().slideToggle();
          });

          // Match Height
          $('.m-height').matchHeight();

        }); // End document ready

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Home page
    'home': {
      init: function() {


      },
      finalize: function() {
        // JavaScript to be fired after the init JS
      }
    },

    // Landing page
    'page_template_the_building': {
      init: function() {

      // Image tooltip/hotspot hovers
      $(function() {
        $('.image-hotspot--hotspot-point').hover( function() {
          $(this).children('.image-hotspot--hotspot-point_tooltip').fadeIn(300);
        },
        function(){
          $(this).children('.image-hotspot--hotspot-point_tooltip').delay(400).fadeOut(300);
          }
        );
      });

      $(function() {
        $('.image-hotspot--hotspot-point_tooltip').hover( function() {
          $(this).fadeIn(300);
        },
        function(){
          $(this).fadeOut(300);
        }
        );
      });

      },
      finalize: function() {
        // JavaScript to be fired after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
